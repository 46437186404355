export const FAQContent = [
  {
    question: "How many people can be in a team?",
    answer:
      "Each team can have between 1 and 4 members. If you don’t have a team but want to be a part of one, you can still sign up alone, and we’ll make accommodations before the event to help you find a group to work with.",
  },
  {
    question: "Who is eligible to participate?",
    answer:
      "Anyone attending any Canadian post-secondary institution can attend, as well as recent grads (Winter 2024)!",
  },
  {
    question: "How much does it cost to attend?",
    answer:
      //  "The participation fee for CodeJam 14 has not yet been determined. Please check back later for more information.",
      "CodeJam 14 will have a $20 admission fee. Please note that applying to the event is free, and you will not be charged until you are accepted. Your $20 includes stickers, food for a whole weekend, and a CodeJam t-shirt!",
  },
  {
    question: "Can I iterate on a past project as my submission?",
    answer:
      "No. Participants should be creating something from scratch once the hacking begins so we can have an even playing field for everyone!",
  },
  {
    question: "Is it too late to apply?",
    answer:
      "Applications closed on October 25th. We post about other upcoming hackathons and events on our Instagram though, so follow us there for more opportunities!",
    // "Yes, applications are open! Click 'Apply' above to fill out the form. Applications are due on Friday, October 25th.", // TODO: update this when applications open again
  },
  {
    question: "Is CodeJam beginner friendly?",
    answer:
      "Yup! In fact, we will be hosting a beginner to coding workshop to learn web development, and will have a separate prize category for beginners!",
  },

  // {
  //   question: 'Have any questions not listed here?',
  //   answer:
  //     "Say hi at codejam@mcgilleus.ca or leave us a message at either our Facebook or Instagram page. We'll try to answer all queries as soon as possible! 🚀",
  // },
];
