import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import DashboardLayout from "../Components/DashboardLayout/DashboardLayout";

import boxfalse from "../../../Resources/tick-square.svg";
import boxtrue from "../../../Resources/ticktrue.svg";

import "./TeamCheckIn.scss";

const TeamCheckIn = ({ apiUrl, currentUser, setErrorMessage, token }) => {
  const teamID = currentUser.teamID;
  const [teamData, setTeamData] = useState({
    name: "",
    projectName: "",
    devpost: "",
    member1: "",
    member2: "",
    member3: "",
    member4: "",
  });

  const [checkedChallenges, setCheckedChallenges] = useState([]);

  const [matadorChecked, setMatadorChecked] = useState(false);
  const [beginnerChecked, setBeginnerChecked] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser.teamID) {
      window.location.href = "/dashboard/editTeam/create";
    } else {
      let newTeamData;
      axios
        .get(apiUrl + "teams/" + currentUser.teamID)
        .then((teamRes) => {
          console.log(teamRes);
          let teamResData = teamRes.data.data;
          newTeamData = {
            ...teamData,
            name: teamResData.name,
            devpost: teamResData.devpost,
            projectName: teamResData.projectName,
          };
          setCheckedChallenges(
            teamResData.checkedInSponsoredChallenges.split(",")
          );
          if (teamResData.checkedInSponsoredChallenges.includes("Matador")) {
            setMatadorChecked(true);
          }
          if (
            teamResData.checkedInSponsoredChallenges.includes("Beginner")
          ) {
            setBeginnerChecked(true);
          }
        })
        .then(() => {
          axios
            .get(apiUrl + "teams/teamMembers/" + currentUser.teamID)
            .then((teamMemberRes) => {
              console.log(teamMemberRes);
              let teamMemberResData = teamMemberRes.data.data;
              newTeamData = {
                ...newTeamData,
                member1:
                  teamMemberResData[0].firstName +
                  " " +
                  teamMemberResData[0].lastName,
              };
              if (teamMemberResData[1]) {
                newTeamData = {
                  ...newTeamData,
                  member2:
                    teamMemberResData[1].firstName +
                    " " +
                    teamMemberResData[1].lastName,
                };
              }
              if (teamMemberResData[2]) {
                newTeamData = {
                  ...newTeamData,
                  member3:
                    teamMemberResData[2].firstName +
                    " " +
                    teamMemberResData[2].lastName,
                };
              }
              if (teamMemberResData[3]) {
                newTeamData = {
                  ...newTeamData,
                  member4:
                    teamMemberResData[3].firstName +
                    " " +
                    teamMemberResData[3].lastName,
                };
              }
              setTeamData(newTeamData);
            });
        });
    }
  }, []);

  const handleCheckboxChange = (e) => {
    if (e.target.value === "Matador") setMatadorChecked(!matadorChecked);
    if (e.target.value === "Beginner") setBeginnerChecked(!beginnerChecked);

    if (e.target.value === "Matador" && e.target.checked) {
      setCheckedChallenges([...checkedChallenges, "Matador"]);
    } else if (e.target.value === "Beginner" && e.target.checked) {
      setCheckedChallenges([...checkedChallenges, "Beginner"]);
    } else {
      setCheckedChallenges(
        checkedChallenges.filter((c) => c !== e.target.value)
      );
    }
  };

  const onSubmit = () => {
    if (teamData.projectName.length < 1) {
      setErrorMessage("Project name must be at least 1 character long");
      return;
    } else if (teamData.projectName.length > 50) {
      setErrorMessage("Project name must be less than 50 characters long");
      return;
    } else if (
      teamData.devpost &&
      !teamData.devpost.toLowerCase().includes("devpost.com")
    ) {
      setErrorMessage("Invalid Devpost link");
      return;
    }

    let membersString = [
      teamData.member1,
      teamData.member2,
      teamData.member3,
      teamData.member4,
    ]
      .filter((c) => c !== "")
      .join(",");

    let requestData = {
      name: teamData.name,
      projectName: teamData.projectName,
      devpost: teamData.devpost,
      members: membersString,
      checkedInSponsoredChallenges: checkedChallenges.toString(),
      teamID: teamID,
    };

    const headers = {
      authorization: token,
    };

    axios
      .put(apiUrl + "teams/checkIn", requestData, { headers })
      .then((res) => {
        console.log(res);
        if (res.data.message.includes("Team checked-in")) {
          navigate("/dashboard/team/" + teamID);
        } else {
          setErrorMessage(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err.response.data.message);
      });
  };

  return (
    <>
      <div className="checkin-page">
        <DashboardLayout title="Team Check-In">
          <div className="checkin-container">
            <div className="checkin-input-box">
              <div className="text">
                <span className="required">*</span> Project Name
              </div>
              <input
                className="checkin-custom-input"
                value={teamData.projectName ? teamData.projectName : ""}
                onChange={(e) => {
                  setTeamData({ ...teamData, projectName: e.target.value });
                }}
              />
            </div>

            <div className="checkin-input-box">
              <div className="text">
                <span className="required">*</span> DevPost Link
              </div>
              <input
                className="checkin-custom-input"
                value={teamData.devpost ? teamData.devpost : ""}
                onChange={(e) => {
                  setTeamData({ ...teamData, devpost: e.target.value });
                }}
              />
            </div>

            <div className="checkin-input-box">
              <div className="text">
                Are you participating in the following categories?
              </div>
              <div className="checkin-checkbox-container">
                <div className="checkbox-input">
                  <input
                    type="checkbox"
                    id="matadorCheckbox"
                    name="matadorCheckbox"
                    className="checkin-checkbox"
                    value="Matador"
                    checked={matadorChecked}
                    onChange={(e) => {
                      handleCheckboxChange(e);
                    }}
                    disabled={false}
                  />

                  <label
                    htmlFor="matadorCheckbox"
                    className="checkin-checkbox-label"
                  >
                    <img className="checkin-checkbox--icon" src={matadorChecked ? boxtrue : boxfalse} />
                    <span className="checkin-checkbox-label-text">Matador Challenge</span>
                  </label>
                </div>

                <div className="checkbox-input">
                  <input
                    type="checkbox"
                    id="beginnerCheckbox"
                    name="beginnerCheckbox"
                    className="checkin-checkbox"
                    value="Beginner"
                    checked={beginnerChecked}
                    onChange={(e) => {
                      handleCheckboxChange(e);
                    }}
                    disabled={false}
                  />

                  <label
                    htmlFor="beginnerCheckbox"
                    className="checkin-checkbox-label"
                  >
                    <img className="checkin-checkbox--icon" src={beginnerChecked ? boxtrue : boxfalse} />
                    <span className="checkin-checkbox-label-text">Beginner</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* Submit button is not shown unless the required fields are filled }
          <button
            className="button submit-button"
            onClick={onSubmit}
            disabled={!teamData.projectName || !teamData.devpost}
          >
            Submit
          </button> */}
          {/* show closed button */}
          <button className="button submit-button" disabled>Check In Closed.</button>
        </DashboardLayout>
      </div>
    </>
  );
};

export default TeamCheckIn;
