export const currentEventContent = {
  title: "CodeJam 14",
  id: "codejam14",
  date: "November 2024",
  edition: "14th Edition | McGill University",
  participants: "",
  prize: "",
  location: "In-Person Attendance",
  link: "",
  team: [
    {
      name: "Sloane Holtby",
      year: "U3",
      department: "Software Engineering & Statistics",
      position: "President",
      photo: require("../Resources/TeamImages/sloane.jpeg"),
      linkedIn:
        "https://www.linkedin.com/in/sloane-holtby-58b76422a/?original_referer=https%3A%2F%2Fwww.google.com%2F",
    },
    /* {
      name: "Mirek Bourdeau",
      year: "U4",
      department: "Computer Science",
      position: "Finance Captain",
      photo: require("../Resources/TeamImages/mirek.jpeg"),
      linkedIn: "http://www.linkedin.com/in/mirek-bourdeau-b1ab53209",
    }, */
    {
      name: "Rei Kong",
      year: "U3",
      department: "Computer Science and Sociology",
      position: "Design Captain",
      photo: require("../Resources/TeamImages/rei.jpeg"),
      linkedIn: "https://www.linkedin.com/in/rei-kong/",
    },
    {
      name: "Gali Kechichian",
      year: "U4",
      department: "Computer Science",
      position: "Design",
      photo: require("../Resources/TeamImages/gali.jpeg"),
      linkedIn: "http://linkedin.com/in/galikechichian",
    },
    {
      name: "Riana Dutta",
      year: "U3",
      department: "Software Engineering",
      position: "Marketing Captain",
      photo: require("../Resources/TeamImages/riana.jpeg"),
      linkedIn: "https://www.linkedin.com/in/riana-dutta/",
    },
    {
      name: "Ariana Li",
      year: "U1",
      department: "Computer Science",
      position: "Marketing",
      photo: require("../Resources/TeamImages/ariana.jpeg"),
      linkedIn: "https://www.linkedin.com/in/arianajli/",
    },
    {
      name: "Lucy Mao",
      year: "U3",
      department: "Computer Science",
      position: "Logistics Captain",
      photo: require("../Resources/TeamImages/lucy.jpeg"),
      linkedIn: "https://www.linkedin.com/in/lucy-mao/",
    },
    {
      name: "James Kidd",
      year: "U2",
      department: "Math and Computer Science",
      position: "Logistics",
      photo: require("../Resources/TeamImages/james.jpeg"),
      linkedIn: "https://www.linkedin.com/in/james-kidd-mtl/",
    },
    {
      name: "Gabriel Lacroix",
      year: "U2",
      department: "Electrical Engineering",
      position: "Logistics",
      photo: require("../Resources/TeamImages/gabriel.jpeg"),
      linkedIn: "https://www.linkedin.com/in/gabelacroix/",
    },
    {
      name: "Serena Elzein",
      year: "U3",
      department: "Computer Science and Biology",
      position: "Sponsorship Captain",
      photo: require("../Resources/TeamImages/serena.jpeg"),
      linkedIn: "https://www.linkedin.com/in/serena-elzein-061b33224/",
    },
    {
      name: "Onur Gul",
      year: "U2",
      department: "Computer Science and Math",
      position: "Sponsorship",
      photo: require("../Resources/TeamImages/onur.jpeg"),
      linkedIn: "https://www.linkedin.com/in/onurgul1/",
    },
    {
      name: "Ella Gutierrez Salazar",
      year: "U2",
      department: "Computer Science",
      position: "Sponsorship",
      photo: require("../Resources/TeamImages/ella.jpeg"),
      linkedIn:
        "https://www.linkedin.com/in/eva-rafaella-gutierrez-salazar-438815225/",
    },
    {
      name: "Anisha Mohapatra",
      year: "U4",
      department: "Computer Science",
      position: "Sponsorship",
      photo: require("../Resources/TeamImages/anisha.jpg"),
      linkedIn: "https://www.linkedin.com/in/anisha-mohapatra-3b6280173/",
    },
    {
      name: "Iris Bussière Kwan",
      year: "U2",
      department: "Computer Science",
      position: "Sponsorship",
      photo: require("../Resources/TeamImages/iris.jpeg"),
      linkedIn: "https://www.linkedin.com/in/iris-bussière-kwan-596648298/",
    },
    {
      name: "James Madden",
      year: "U2",
      department: "Computer Engineering",
      position: "Website Captain",
      photo: require("../Resources/TeamImages/jamesMadden.jpeg"),
      linkedIn: "https://www.linkedin.com/in/jamesbmadden/",
    },
    {
      name: "Behrad Rezaie",
      year: "U3",
      department: "Computer Engineering",
      position: "Website",
      photo: require("../Resources/TeamImages/behrad.jpeg"),
      linkedIn: "https://www.linkedin.com/in/behradrezaie/",
    },
    {
      name: "Amgad El Gamal",
      year: "U3",
      department: "Software Engineering",
      position: "Website",
      photo: require("../Resources/TeamImages/amgad.jpeg"),
      linkedIn: "https://www.linkedin.com/in/amgadelgamal/",
    },
    {
      name: "MacLaren Scott",
      year: "U2",
      department: "Computer Science and Math",
      position: "Website",
      photo: require("../Resources/TeamImages/mclaren.jpeg"),
      linkedIn: "https://www.linkedin.com/in/maclarenscott/",
    },
    {
      name: "Saif Abdelkefi",
      year: "U5",
      department: "Computer Science",
      position: "Website",
      photo: require("../Resources/TeamImages/saif.jpeg"),
      linkedIn: "https://www.linkedin.com/in/seifabdelkefi/",
    },
  ],
};
