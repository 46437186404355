import React, { useState } from "react";
import propTypes from "prop-types";
import "../../../Styles/variables.scss";
import showPasswordLogo from "../../../Resources/showPassword.svg";
import hidePasswordLogo from "../../../Resources/hidePassword.svg";
import errorImage from "../../../Resources/error.svg";
import { Link, useNavigate } from "react-router-dom";

export default function Signup({ token, saveToken, apiUrl }) {
  const initialValues = { email: "", password: "" };
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [values, setValues] = useState(initialValues);
  const navigate = useNavigate();

  // submit the entered email to the register function. If the user accepted to CodeJam + paid,
  // they will be sent a password to their email and redirected to the login page.
  const handleSubmit = (event) => {
    const { email, registration, password, confirmPassword } = values;

    if (password != confirmPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }

    // send the api request
    fetch(apiUrl + "users/signup", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, registrationCode: registration, password }),
    })
      .then(async (response) => {
        if (response.ok) {
          // go to the sign up page
          const { token } = await response.json();
          saveToken(token);
        } else {
          const { message } = await response.json();
          setEmailError(message);
        }
      })
      .catch((error) => {
        setEmailError(error.message);
      });
  };

  const handleChange = (e) => {
    //update the values list with the values in email and password fields
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  if (token) {
    return (window.location.href = "/dashboard/team");
  }

  return (
    <div className="login-container">
      <div className="title-container">
        <h4 className="h4">Create Your Account</h4>
        <p className="body1 login-explainer">
          Enter your email, create a password, and the registration code you
          received.
          <br />
          Already set up your account? You can{" "}
          <Link className="link" to="/login">
            sign in here
          </Link>
          .
        </p>
        <div className="email-container">
          <div className="email-header body2">Email</div>
          <div className="input-box">
            <input
              className={
                emailError
                  ? "email-box-failure body1"
                  : "email-box-success body1"
              }
              name="email"
              onChange={handleChange}
              placeholder="email@address.com"
              type="email"
              value={values.email}
            />
          </div>
          <div className="error-messages-container">
            {emailError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages body2">{emailError}</div>
          </div>
          <div className="email-header body2">Registration Code</div>
          <div className="input-box">
            <input
              className="email-box-success body1"
              name="registration"
              onChange={handleChange}
              placeholder=""
              type="input"
              value={values.registration}
            />
          </div>
          <div className="email-header body2">Password</div>
          <div className="input-box">
            <input
              className="email-box-success body1"
              name="password"
              onChange={handleChange}
              placeholder=""
              type="password"
              value={values.password}
            />
          </div>
          <div className="email-header body2">Confirm Password</div>
          <div className="input-box">
            <input
              className={
                passwordError
                  ? "email-box-failure body1"
                  : "email-box-success body1"
              }
              name="confirmPassword"
              onChange={handleChange}
              placeholder=""
              type="password"
              value={values.confirmPassword}
            />
          </div>
          <div className="error-messages-container">
            {passwordError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages body2">{passwordError}</div>
          </div>
          <button className="login-button" onClick={handleSubmit}>
            <b className="h6">Register</b>
          </button>
        </div>
      </div>
    </div>
  );
}

Signup.propTypes = {
  saveToken: propTypes.func.isRequired,
};

//this method validates the email and password values and returns a list containing error messages
const validate = (values) => {
  const errorlist = { email: "", password: "" };
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (!values.email) {
    errorlist.email = "Email is required.";
  } else if (!regex.test(values.email)) {
    errorlist.email = "Please enter a valid email.";
  } else {
    errorlist.email = "";
  }
  if (!values.password) {
    errorlist.password = "Password is required.";
  } else {
    errorlist.password = "";
  }
  return errorlist;
};
