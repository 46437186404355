import react from "react";
import { Routes, Route } from "react-router-dom";
import NavBar from "../../Global Components/NavBar/NavBar";
import Home from "./Home/Home";
import Team from "./Team/Team";
import Past from "./Past/Past";
import Sponsorship from "./Sponsorship/Sponsorship";
import { NavBarMenuContent } from "../../Data/NavBarMenuContent";
import LandAck from "../../Global Components/LandAck/LandAck";
import Signup from "./Signup/Signup";
import Login from "../Website/Login/Login";
import Register from "./Register/Register";
import Application from "./Application/Application";
import ForgotPassword from "./ForgotPassword/ForgotPassword";

const Website = ({ token, saveToken, apiUrl }) => {
  return (
    <>
      <div className="app-container">
        <div className="app-wrapper">
          <NavBar data={NavBarMenuContent} />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/team" element={<Team />} />
            <Route exact path="/past" element={<Past />} />
            <Route
              exact
              path="/login"
              element={
                <Login saveToken={saveToken} token={token} apiUrl={apiUrl} />
              }
            />
            <Route exact path="/sponsorship" element={<Sponsorship />} />
            <Route
              exact
              path="/signup"
              element={
                <Signup apiUrl={apiUrl} saveToken={saveToken} token={token} />
              }
            />
            <Route
              exact
              path="/register"
              element={<Register apiUrl={apiUrl} />}
            />
            <Route
              exact
              path="/application2"
              element={<Application apiUrl={apiUrl} />}
            />
            <Route
              exact
              path="/forgot-password"
              element={<ForgotPassword apiUrl={apiUrl} />}
            />
          </Routes>
        </div>
        <div className="footer-section">
          <div className="land-ack-section">
            <LandAck />
          </div>
        </div>
      </div>
    </>
  );
};

export default Website;
