import React, { useEffect, useState } from "react";
import "./Team.scss";
import plusIcon from "../../../Resources/plus2.svg";
import devpostLogo from "../../../Resources/devpostLogo.png";
import upRightArrow from "../../../Resources/upRightArrow.svg";
import { useParams, Link } from "react-router-dom";
import DiscordLogo from "../../../Resources/Icons/Discord_Icon.svg";
import axios from "axios";
import { AddMemberModal } from "./Components/AddMemberModal/AddMemberModal";
import { ConfirmationModal } from "../Components/ConfirmationModal/ConfirmationModal";
import DashboardLayout from "../Components/DashboardLayout/DashboardLayout";

const Team = ({ apiUrl, currentUser, setErrorMessage, token }) => {
  let teamID = useParams().id;

  const [teamMembers, setTeamMembers] = useState(null);
  const [copiedDiscord, setCopiedDiscord] = useState(false);

  const [teamData, setTeamData] = useState({
    name: "",
    teambio: "",
    devpost: "",
    id: teamID,
    unique_join_code: "",
    isCheckedIn: false,
  });

  // true if user is ON the team that is being viewed
  const [isOnTeam, setIsOnTeam] = useState(false);

  const [addMemberModalOpen, setAddMemberModalOpen] = useState(false);
  const [leaveTeamModalOpen, setLeaveTeamModalOpen] = useState(false);

  const toggleAddMemberModal = () => {
    setAddMemberModalOpen(!addMemberModalOpen);
  };

  const toggleLeaveTeamModal = () => {
    setLeaveTeamModalOpen(!leaveTeamModalOpen);
  };

  const leaveTeam = () => {
    const config = {
      method: "put",
      url: apiUrl + `teams/leaveTeam/${teamData.id}`,
      headers: {
        Authorization: token,
      },
    };
    axios
      .request(config)
      .then((response) => {
        currentUser.teamID = null;
        window.location.href = "/dashboard/editTeam/create";
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message);
      });
  };

  useEffect(() => {
    if (teamID == "create" || !currentUser.teamID) {
      window.location.href = "/dashboard/editTeam/create";
    } else {
      if (!teamID) {
        teamID = currentUser.teamID;
      }
      if (currentUser.teamID == teamID) {
        setIsOnTeam(true);
      }
      axios
        .get(apiUrl + "teams/" + teamID)
        .then((teamRes) => {
          let teamResData = teamRes.data.data;
          setTeamData(teamResData);
        })
        .then(() => {
          axios
            .get(apiUrl + "teams/teamMembers/" + teamID)
            .then((teamMemberRes) => {
              setTeamMembers(teamMemberRes.data.data);
            });
        });
    }
  }, []);

  // team member card
  const TeamMember = ({
    id,
    firstName,
    lastName,
    pronouns,
    schoolOrCompany,
    picture,
    discord,
  }) => {
    console.log(picture);
    return (
      <Link
        className="team-member"
        key={firstName + lastName}
        to={"/dashboard/profile/" + id}
      >
        <img src={picture ? picture : null} className="profile" />
        <div className="member-info">
          <h3 className="name">
            {firstName} {lastName}
          </h3>
          <div className="pronouns">{pronouns}</div>
          <div className="university">{schoolOrCompany}</div>
          <div className="profile--links">
            <ul>
              {discord ? (
                <li>
                  <a
                    className="profile--social-link"
                    onClick={() => {
                      navigator.clipboard.writeText(discord);
                      setCopiedDiscord(true);
                    }}
                  >
                    <img className="icon" src={DiscordLogo} />
                    <span>{discord}</span>
                  </a>
                  {copiedDiscord && <span>Copied!</span>}
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </Link>
    );
  };

  return teamMembers ? (
    <>
      {addMemberModalOpen && (
        <AddMemberModal
          toggleModal={toggleAddMemberModal}
          uniqueJoinCode={teamData.unique_join_code}
        />
      )}
      {leaveTeamModalOpen && (
        <ConfirmationModal
          toggleModal={toggleLeaveTeamModal}
          headerText="U sure?"
          subheadingText="If you want to rejoin you will need to ask a member for the join code"
          buttonText="Leave Team"
          onClick={leaveTeam}
        />
      )}
      <DashboardLayout
        title="Team"
        style={
          addMemberModalOpen || leaveTeamModalOpen
            ? { filter: "blur(5px)" }
            : null
        }
      >
        <div className="team-container">
          <div className="team-header">
            <div className="team-name">
              {teamData.name.length > 50
                ? `${teamData.name.substring(0, 50)}...`
                : teamData.name}
            </div>
            {/* check in closed, turn off the edit team feature */}
            {/*isOnTeam && (
              <Link className="button" to={"/dashboard/editTeam/" + teamID}>
                Edit Team
              </Link>
            )*/}
          </div>
          <div className="team-members">
            {teamMembers.map((member) => {
              return TeamMember(member);
            })}
            {isOnTeam && teamMembers.length < 4 ? (
              <div className="add-member" onClick={toggleAddMemberModal}>
                <div>
                  <img className="plus-icon" src={plusIcon} />
                </div>
              </div>
            ) : null}
          </div>
          <div className="footer">
            <div className="description">
              {teamData.teambio ? teamData.teambio : "No team description yet!"}
            </div>
            {teamData.devpost && (
              <div className="devpost">
                <img src={devpostLogo} className="devpostLogo" />
                <a href={teamData.devpost} className="devpostText">
                  Devpost
                </a>
                <img src={upRightArrow} className="devpostArrow" />
              </div>
            )}
          </div>
          {/* check in closed, turn off the leave team feature */}
          {/*isOnTeam && (
            <a className="button leave-button" onClick={toggleLeaveTeamModal}>
              Leave Team
            </a>
          )*/}
        </div>
      </DashboardLayout>
    </>
  ) : (
    <div></div>
  );
};

export default Team;
