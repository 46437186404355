import react from "react";
import boxfalse from "../../../Resources/tick-square.svg";
import boxtrue from "../../../Resources/ticktrue.svg";
import { useState } from "react";
import filterIcon from "../../../../src/Resources/Vector.svg";
import searchIcon from "../../../../src/Resources/search-normal.svg";
import "./TeamBrowser.scss";
import TeamTable from "./TeamTable/TeamTable";
import DashboardLayout from "../Components/DashboardLayout/DashboardLayout";

const TeamBrowser = ({ currentUser, apiUrl }) => {
  const [searchInput, setSearchInput] = useState("");
  //const [isDropdownOpen, setIsDropdownOpen] = useState(false); // New state for the dropdown
  const [isFilteredByLooking, setIsFilteredByLooking] = useState(false);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  function showDropdown() {
    document.getElementById("myDropdown").classList.toggle("show");
  }

  const ChangeIsLooking = () => {
    setIsFilteredByLooking(!isFilteredByLooking);
  };

  return (
    <DashboardLayout title="Team Gallery">
      <div className="team-container">
        <div className="search-box">
          <input
            className="custom-search"
            placeholder="Search"
            value={searchInput}
            onChange={handleSearchChange}
          />

          <div class="dropdown">
            <div className="button" onClick={showDropdown}>
              <img src={filterIcon} alt="Filter Icon" />
              Filter
            </div>
            <div id="myDropdown" class="dropdown-content">
              <div className="filterOption">
                Looking
                <button className="unstyled-button" onClick={ChangeIsLooking}>
                  {isFilteredByLooking ? (
                    <img src={boxtrue}></img>
                  ) : (
                    <img src={boxfalse}></img>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <TeamTable
          apiUrl={apiUrl}
          searchInput={searchInput}
          isFilteredByLooking={isFilteredByLooking}
        />
      </div>
    </DashboardLayout>
  );
};

export default TeamBrowser;
