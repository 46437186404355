import react, { useState } from "react";
import { useParams, Link } from "react-router-dom";

import "./DashboardLayout.scss";

const DashboardLayout = ({ title, children }) => {
  return (
    <div className="db--wrapper">
      <div className="db-page">
        <h1 className="db--title">{title}</h1>

        <article className="db--main">{children}</article>
      </div>
    </div>
  );
};

export default DashboardLayout;
