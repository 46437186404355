import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import "./EditTeam.scss";
import DashboardLayout from "../Components/DashboardLayout/DashboardLayout";

const EditTeam = ({ apiUrl, currentUser, setErrorMessage, token }) => {
  const teamID = useParams().id;

  const [teamData, setTeamData] = useState({
    name: "",
    teambio: "",
    devpost: "",
    looking: true,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (teamID && !teamID.includes("create") && teamID == currentUser.teamID) {
      axios
        .get(apiUrl + "teams/" + teamID)
        .then((res) => {
          setTeamData(res.data.data);
        })
        .catch((err) => {
          setErrorMessage(err.response.data.message);
        });
    } else {
      // if they are on a team already move to look at their team
      if (currentUser.teamID > 0) {
        navigate("/dashboard/editTeam/" + currentUser.teamID);
      } else if (teamID != "create") {
        // if not on a team go to create page
        navigate("/dashboard/editTeam/create");
      }
    }
  }, []);

  const joinTeam = () => {
    const config = {
      method: "put",
      url: apiUrl + `teams/joinTeam/${currentUser.id}`,
      headers: {
        Authorization: token,
      },
      data: {
        unique_join_code: teamData.joinCode,
      },
    };
    axios
      .request(config)
      .then((response) => {
        currentUser.teamID = response.data.data.teamID;
        navigate("/dashboard/team/");
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message);
      });
  };

  const onSubmit = () => {
    if (teamData.name.length < 1) {
      setErrorMessage("Team name must be at least 1 character long");
      return;
    } else if (teamData.name.length > 50) {
      setErrorMessage("Team name must be less than 50 characters long");
      return;
    } else if (teamData.teambio.length > 500) {
      setErrorMessage("Team bio must be less than 500 characters long");
      return;
    } else if (
      teamData.devpost &&
      !teamData.devpost.toLowerCase().includes("devpost.com")
    ) {
      setErrorMessage("Invalid Devpost link");
      return;
    }

    if (teamID == "create") {
      let unique_join_code =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
      let newTeamData = {
        ...teamData,
        creator: currentUser.id,
        unique_join_code: unique_join_code,
      };
      axios
        .post(apiUrl + "teams", newTeamData)
        .then((res) => {
          if (res.data.message.includes("Team added")) {
            navigate("/dashboard/team/" + res.data.data.id);
          } else {
            setErrorMessage(res.data.message);
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data.message);
        });
    } else {
      let updateTeamData = {
        ...teamData,
        userID: currentUser.id,
      };
      axios
        .put(apiUrl + "teams/updateTeam/" + teamID, updateTeamData)
        .then((res) => {
          console.log(res);
          if (res.data.message.includes("Team name is now")) {
            navigate("/dashboard/team/" + teamID);
          } else {
            setErrorMessage(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          setErrorMessage(err.response.data.message);
        });
    }
  };

  return (
    <>
      <div className="team-page2">
        <DashboardLayout title="Teams">
          <p style={{padding: '32px', textAlign: 'center'}}>Team joining and editing is now closed.</p>
        </DashboardLayout>
        {/*teamID == "create" && (
          <>
            <h1>Join an Existing Team</h1>
            <div className="team-container">
              <div className="team-input-box" style={{ gap: "10px" }}>
                <div className="text">Code</div>
                <div className="join-input-container">
                  <input
                    className="team-custom-input"
                    value={teamData.joinCode ? teamData.joinCode : ""}
                    onChange={(e) => {
                      setTeamData({ ...teamData, joinCode: e.target.value });
                    }}
                  />
                  <div className="button join-button" onClick={joinTeam}>
                    Join
                  </div>
                </div>
              </div>
              <div className="text" style={{ textAlign: "center" }}>
                Ask the team for their Code and enter it above.
              </div>
              <div className="text" style={{ textAlign: "center" }}>
                Don't have a team yet? Find one{" "}
                <Link to="team-browser">here</Link>
              </div>
            </div>
          </>
        )}
        <h1>{teamID == "create" ? "Create a New Team" : "Edit Team"}</h1>
        <div className="team-container">
          <div className="team-input-box">
            <div className="text">
              <span className="required">*</span> Team Name
            </div>
            <input
              className="team-custom-input"
              value={teamData.name ? teamData.name : ""}
              onChange={(e) => {
                setTeamData({ ...teamData, name: e.target.value });
              }}
            />
          </div>
          <div className="team-input-box">
            <div className="text">
              <span className="required">*</span> Description
            </div>
            <textarea
              className="team-custom-input team-bio-input"
              value={teamData.teambio ? teamData.teambio : ""}
              onChange={(e) => {
                setTeamData({ ...teamData, teambio: e.target.value });
              }}
            />
          </div>
          <div className="team-input-box">
            <div className="text">DevPost Link</div>
            <input
              className="team-custom-input"
              value={teamData.devpost ? teamData.devpost : ""}
              onChange={(e) => {
                setTeamData({ ...teamData, devpost: e.target.value });
              }}
            />
          </div>
          <div className="text">Is your team looking for more members?</div>
          <div className="team-input-box">
            <div className="team-checkbox-container">
              <input
                type="checkbox"
                id="looking-for-members"
                className="team-checkbox"
                checked={teamData.looking}
                onChange={(e) => {
                  setTeamData({ ...teamData, looking: e.target.checked });
                }}
              />
              <label
                htmlFor="looking-for-members"
                className="team-checkbox-label"
              ></label>
              <div className="team-checkbox-label-text">
                {teamData.looking
                  ? "Yes, we are looking for more members"
                  : "No, we are not"}
              </div>
            </div>
          </div>

          {/* Submit button is not shown unless the required fields are filled *\/}
          {teamData.name && teamData.teambio && (
            <div className="button submit-button" onClick={onSubmit}>
              Submit
            </div>
          )}
        </div> */}
      </div>
    </>
  );
};

export default EditTeam;
