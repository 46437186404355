import React, { useEffect, useState } from "react";
import axios from "axios";

import DashboardLayout from "../Components/DashboardLayout/DashboardLayout";
import boxfalse from "../../../Resources/tick-square.svg";
import boxtrue from "../../../Resources/ticktrue.svg";

import "./Withdraw.scss";

const Withdraw = ({ apiUrl, currentUser, setErrorMessage, token }) => {
  const teamID = currentUser.teamID;

  const [teamName, setTeamName] = useState("");

  const [isConfirmationChecked, setIsConfirmationChecked] = useState(false);

  useEffect(() => {
    if (!currentUser.teamID) {
      window.location.href = "/dashboard/editTeam/create";
    } else {
      axios.get(apiUrl + "teams/" + currentUser.teamID).then((teamRes) => {
        console.log(teamRes);
        let teamResData = teamRes.data.data;

        setTeamName(teamResData.name);
      });
    }
  }, []);

  const handleCheckboxChange = (e) => {
    setIsConfirmationChecked(!isConfirmationChecked);
  };

  const onSubmit = () => {
    let requestData = {
      name: teamName,
      teamID: teamID,
    };

    const headers = {
      authorization: token,
    };

    axios
      .put(apiUrl + "teams/withdraw", requestData, { headers })
      .then((res) => {
        console.log(res);
        if (res.data.message.includes("Team withdrawn")) {
          navigate("/dashboard/team/" + teamID);
        } else {
          setErrorMessage(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err.response.data.message);
      });
  };

  return (
    <>
      <div className="withdraw-page">
        <DashboardLayout title="Team Withdrawal">
          <div className="withdraw-container">
            <div className="withdraw-input-box">
              <div className="text">
                <span className="required">*</span> Are you sure you want to
                withdraw from the judging round?
              </div>
              <div className="withdraw-checkbox-container">
                <div className="checkbox-input">
                  <input
                    type="checkbox"
                    id="confirmCheckbox"
                    name="confirmCheckbox"
                    className="withdraw-checkbox"
                    value={isConfirmationChecked}
                    checked={isConfirmationChecked}
                    onChange={(e) => {
                      handleCheckboxChange(e);
                    }}
                  />

                  <label
                    htmlFor="confirmCheckbox"
                    className="withdraw-checkbox-label"
                  >
                    <img className="withdraw-checkbox--icon" src={isConfirmationChecked ? boxtrue : boxfalse} />
                    <span className="withdraw-checkbox-label-text">Yes</span>
                  </label>
                </div>
              </div>
            </div>

            {/* Submit button is not clickable unless the required fields are filled}
            <button className="button submit-button" disabled={!isConfirmationChecked} onClick={onSubmit}>
              Withdraw
            </button> */}
            {/* withdraw period over, show disabled button */}
            <button className="button submit-button" disabled>
              Withdrawals Closed.
            </button>
          </div>
        </DashboardLayout>
      </div>
    </>
  );
};

export default Withdraw;
